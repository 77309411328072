import { Button, Modal } from '@mui/material';
import { FlexLayout } from '@Styled/utilities';
import React from 'react';
import styled from 'styled-components';

type Props = {
	ban: boolean;
	onConfirmation: any;
	handleModelClose: () => void;
	openModel: boolean;
};

const BanModel: React.FC<Props> = ({
	ban,
	handleModelClose,
	onConfirmation,
	openModel,
}) => {
	return (
		<Modal
			open={openModel}
			onClose={handleModelClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Container>
				<Title>
					Are you sure you want {ban ? 'ban' : 'activate'} this user?
				</Title>
				<FlexLayout
					alignItems="center"
					justifyContent="flex-end"
					marginBottom="7px"
					marginTop="26px"
				>
					<CancelBtn variant="text" onClick={handleModelClose}>
						No
					</CancelBtn>
					<OkBtn variant="text" onClick={onConfirmation}>
						Yes
					</OkBtn>
				</FlexLayout>
			</Container>
		</Modal>
	);
};

const Container = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 280px;
	min-height: 136px;
	border-radius: 4px;
	border: 0;
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.38);
	margin: 39px 80px 50px 11.4px;
	padding: 12px 0 0;
	background-color: #ffffff;
	outline: none;
`;

const Title = styled.p`
	width: 232px;
	height: 40px;
	margin-left: 24px;
	font-weight: normal;
	text-align: left;
	font-size: 14px;
	margin-top: 20px;
	color: rgba(0, 0, 0, 0.6);
`;

const CancelBtn = styled(Button)`
	font-size: 14px;
	padding: 6px 16px;
	color: #ff8532;
	outline: none;
	background-color: #fff;
`;

const OkBtn = styled(Button)`
	font-size: 14px;
	padding: 6px 16px;
	color: #6200ee;
	outline: none;
	background-color: #fff;
`;

export default BanModel;
