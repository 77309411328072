import {
	Autocomplete,
	Button,
	Checkbox,
	Popper,
	TextField,
} from '@mui/material';
import React, { FC, SyntheticEvent, useCallback, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { CourseWithPrerequisites } from '@Services/classrooms/classrooms.res.types';
import { useDataProvider } from './DataProvider';
import { FlexLayout } from '@Styled/utilities';
import styled from 'styled-components';
import { useImmer } from 'use-immer';

type Props = {
	handleModelClose: React.Dispatch<boolean>;
};

const PopperMy = function (props: any) {
	return <PopperStyle {...props} />;
};

const AddSequenceAutoComplete: FC<Props> = ({ handleModelClose }) => {
	const { inSequence, outSequence, setOutSequence } =
		useDataProvider<CourseWithPrerequisites>();

	const [selected, setSelected] = useState<string | null>();
	const [array, setArray] = useImmer<Array<CourseWithPrerequisites>>([]);

	const selectedSequence = useCallback(() => {
		const max =
			inSequence &&
			inSequence
				?.map((_) => _.sequence)
				.reduce((a: any, b: any) => {
					return Math.max(a, b);
				}, 0);
		const nArray = array?.map((_, index) => {
			return Object.assign({}, _, { sequence: (max as number) + index + 1 });
		});
		inSequence?.push(...nArray);

		const sortedArr = outSequence?.filter(function (val) {
			return array.indexOf(val) === -1;
		});

		setOutSequence(sortedArr);
		handleModelClose(false);
	}, [selected]);

	return (
		<>
			<AutocompleteStyle
				multiple
				id="checkboxes-tags-demo"
				readOnly={false}
				filterSelectedOptions
				disableCloseOnSelect
				clearOnBlur={true}
				PopperComponent={PopperMy}
				onChange={(e: SyntheticEvent<Element, Event>, v: any) => {
					setSelected(e.currentTarget.textContent);
					setArray(v);
				}}
				options={outSequence as readonly CourseWithPrerequisites[]}
				groupBy={(option) => (option as CourseWithPrerequisites)?.section?.name}
				getOptionLabel={(option: unknown) =>
					(option as CourseWithPrerequisites).name
				}
				renderOption={(props, option, { selected }) => (
					<>
						<li {...props}>
							<CheckboxStyle
								checkedIcon={<CheckBoxIcon fontSize="small" />}
								style={{ marginRight: 8 }}
								checked={selected}
							/>
							<Label> {(option as CourseWithPrerequisites).name} </Label>
						</li>
					</>
				)}
				renderTags={(options: unknown) => {
					return (options as unknown as CourseWithPrerequisites[])?.map(
						(_: CourseWithPrerequisites) => (
							<Text>{(_ as CourseWithPrerequisites).name}, &nbsp;</Text>
						)
					);
				}}
				renderInput={(params) => {
					return <TextField {...params} placeholder="Search" />;
				}}
			/>
			<FlexLayout justifyContent="flex-end" alignItems="flex-end" height="100%">
				<Button
					variant="text"
					color="warning"
					onClick={() => {
						handleModelClose(false);
					}}
				>
					Cancel
				</Button>
				<Button
					variant="text"
					color="secondary"
					onClick={() => {
						selectedSequence();
					}}
				>
					Confirm
				</Button>
			</FlexLayout>
		</>
	);
};

const AutocompleteStyle = styled(Autocomplete)`
	.MuiOutlinedInput-root {
		width: 20rem;
		margin: 0 auto;
	}
`;

const Header = styled.div`
	font-size: 1rem;
	font-weight: 500;
	margin-left: 15px;
	color: #251b33;
`;

const Text = styled.div`
	font-size: 1rem;
	font-weight: 500;
	color: #251b33;
`;

const PopperStyle = styled(Popper)`
	height: 12.6rem;
	width: 20rem;
	overflow-y: scroll;
	overflow-x: hidden;
	border-radius: 4px;
	box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.38);

	&::-webkit-scrollbar {
		display: none;
	}
`;

const Label = styled.p`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 70px;
`;

const CheckboxStyle = styled(Checkbox)`
	padding: 0;
`;

export default AddSequenceAutoComplete;
