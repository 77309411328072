import { Modal } from '@mui/material';
import { FlexLayout } from '@Styled/utilities';
import React from 'react';
import styled from 'styled-components';
import AddSequenceAutoComplete from './AddSequenceAutoComplete';
import { Container, Header } from './SyncUsersModel';

type Props = {
	handleModelClose: React.Dispatch<boolean>;
	openModel: boolean;
};

const AddSequenceModel: React.FC<Props> = ({ handleModelClose, openModel }) => {
	return (
		<>
			<Modal
				open={openModel}
				onClose={() => {
					handleModelClose(false);
				}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<ModelContainer>
					<FlexLayout
						justifyContent="center"
						gridGap="1.5rem"
						flexDirection="column"
						height="100%"
					>
						<Header>
							Please select the courses you want to add to the sequence
						</Header>
						<AddSequenceAutoComplete handleModelClose={handleModelClose} />
					</FlexLayout>
				</ModelContainer>
			</Modal>
		</>
	);
};

const ModelContainer = styled(Container)`
	width: 27rem;
	height: 26.188rem;
	padding: 10px;
`;

export default AddSequenceModel;
